<template>
  <div class="fee-container">
    <div class="banner">

    </div>
    <h1 class="secondary"> کارمزد و سطوح کاربری </h1>
    <div class="text-container">
      <div class="how-works">
        <h3 class="secondary"> چگونگی محاسبه کارمزد </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
      </div>
      <div class="how-works">
        <h3 class="secondary"> کارمزد معاملات و سطوح </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
      </div>
      <!-- <div class="levels">
        <div class="line1"></div>
        <span class="box1">
                    سطح کاربری ۱
                </span>
        <div class="line2"></div>
        <span class="box2">
                    سطح کاربری ۲
                </span>
        <div class="line3"></div>
        <span class="box3">
                    سطح کاربری ۳
                </span>
        <div class="line4"></div>
        <span class="box4">
                    سطح کاربری ۴
                </span>
        <div class="line5"></div>
      </div> -->
      <div class="fee-table">
        <table>
          <tr class="thead">
            <td style="text-align:start"> حجم معاملات در یک ماه </td>
            <td style="text-align:center"> کارمزد برداشت </td>
            <td style="text-align:end"> سطح کاربری </td>
          </tr>
          <tr class="tbody" v-for="(level,index) in levels" :key="index">
            <td style="text-align:start"> {{feeAmounts[index]}} </td>
            <td style="text-align:center">% {{level.wagePercent}} </td>
            <td :class="levelClass[index]" style="text-align:end"> {{levelArray[index]}} </td>
          </tr>
        </table>
      </div>
      <div class="how-works">
        <h3 class="secondary"> کارمزد برداشت ارزی </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
      </div>
      <div class="fee-table">
        <table>
          <tr class="thead">
            <td style="text-align:start"> حجم معاملات در یک ماه </td>
            <td style="text-align:center"> حداقل میزان برداشت </td>
            <td style="text-align:end"> کارمزد برداشت </td>
          </tr>
          <tr class="tbody hover" v-for="(coin,index) in coins" :key="index" >
            <div class="coin">
              <img :src="'/coins/'+coin.coin+'.png'" alt="">
              <div class="unit remove2">
                                    <span class="faunit">
                                        {{$coinLabel[coin.coin]}}
                                    </span>
                <span class="enunit">
                                        {{$coinUnit[coin.coin]}}
                                    </span>
              </div>
            </div>
            <td style="text-align:center"> {{coin.tokenTypeDetails[0].minWithdraw}} {{$coinUnit[coin.coin]}} </td>
            <td style="text-align:end">% {{coin.tokenTypeDetails[0].withdrawalFee}} </td>
          </tr>
        </table>
      </div>
      <div class="how-works">
        <h3 class="secondary"> کارمزد برداشت   تومانی </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Fees',
  data() {
    return {
      levels:[],
      coins:[],
      feeAmounts:['کمتر از ۲۰ میلیون تومان','۵۰-۲۰ میلیون تومان','۵۰-۱۵۰ میلیون تومان','بیش از ۱۵۰ میلیون تومان'],
      levelArray:['سطح۱','سطح۲','سطح۳','سطح۴'],
      levelClass:['lvl1','lvl2','lvl3','lvl4'],
    }
  },
  methods:{
    async getLevels(){
      const res = await this.$axios.get('/users/account-levels');
      this.levels = res.filter(a => a.userAccountLevel !== "SUPERVISOR")
    },
    async getCoins(){
      const res = await this.$axios.get('/coins');
      this.coins=res.content.filter(a => a.p2pSupported ==true)
      this.coins=this.coins.filter(a => a.coin !== 'TOMAN')

    }
  },
  mounted() {
    this.getLevels();
    this.getCoins();
  },
}
</script>

<style lang="scss" scoped>

h1{
  font-weight: 700;
  font-size: clamp(28px,3vw,36px);
  color: var(--secondary);
  align-self: center;
}


.banner{
  background: url('../../assets/public-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
padding-bottom: 100px;

}
.enunit{
  color: var(--a-hover) !important;
  font-weight: 400 !important;
  font-size: clamp(10px,1vw,12px) !important;
}
.coin{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  img{
    width: 36px;
    height: 36px;
  }
  .unit{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}
.lvl1{
  color: var(--orange)  !important;
}
.lvl2{
  color: var(--dark-green)  !important;
}
.lvl3{
  color: var(--blue) !important;
}
.lvl4{
  color: var(--primary) !important;
}
.fee-table{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  table{
    width: 100%;
    td{
      width: 33%;
      height: 56px;
      padding: 0 10px;
      font-weight: 400;
      font-size:clamp(14px, 2vw ,16px) ;
    }
  }
  .tbody{
    td{
      color: var(--black);
    }
  }
  .thead{
    td{
      color: var(--a-hover);
    }
  }
}
.box1{
  width: 20%;
  color: white;
  background: rgba(240, 148, 48, 0.7);
  border: 2px solid rgba(240, 148, 48, 1);
  text-align: center;
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px,1vw,12px);
}
.box2{
  width: 20%;
  color: white;
  background: rgba(41, 135, 156, 0.6);
  border: 2px solid rgba(41, 135, 156, 1);
  text-align: center;
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px,1vw,12px);
}
.box3{
  width: 20%;
  color: white;
  border: 2px solid rgba(38, 138, 255, 1);
  background: rgba(38, 138, 255, 0.6);
  text-align: center;
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px,1vw,12px);
}
.box4{
  width: 20%;
  color: white;
  border: 2px solid rgba(110, 120, 255, 1);
  background: rgba(110, 120, 255, 0.6);
  text-align: center;
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px,1vw,12px);
}
.levels{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  height: 30px;
  border-right: 1px solid rgba(240, 148, 48, 1);
  border-left: 1px solid rgba(110, 120, 255, 1);
  .line5{
    width: 50px;
    border-bottom: 1px solid rgba(110, 120, 255, 1)
  }
  .line4{
    width: 50px;
    height: 1px;
    // border: 1px solid;
    background: linear-gradient(90deg, #6E78FF 0%, #268AFF 100%);
  }
  .line3{
    width: 50px;
    height: 1px;
    background: linear-gradient(90deg, #268AFF 0%, #29879C 100%);
  }
  .line2{
    width:50px;
    height: 1px;
    background: linear-gradient(90deg, #29879C 0%, #F09430 100%);
  }
  .line1{
    width:50px;
    border-bottom:1px solid rgba(240, 148, 48, 1);
  }
}
.text-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 72px;
  padding: 40px 120px;
}
.how-works{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  h3{
    font-weight: 700;
    font-size: clamp(24px,3vw,28px);
    color: var(--secondary);
  }
}
.fee-container{
  display: flex;
  flex-direction: column;
  background: var(--homepage-bg);
  row-gap: 72px;
}
@media only screen and(max-width:1100px){
  .text-container{
    padding: 20px;
  }
  .fee-table{
    width: 100%;
  }
  .banner{
    background: unset !important;
    height: 250px !important;
  }
}
@media only screen and(max-width:700px){
  .levels{
    height: unset;
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
    border: none;
  }
  .line1,.line2,.line3,.line4,.line5{
    display: none;
  }
  .box1,.box2,.box3,.box4{
    width: 50%;
    height: 30px;
  }
}
</style>